function Cards() {
    return (
        <>
            <div id="cards">
                <div className="card" data-color="orange">
                    <img className="card-front-image card-image"
                        src="./images/banner-images/Q9w8ojCU.jpeg?format=auto&quality=80" alt='banner' />
                    <div className="card-faders">
                        <img className="card-fader card-image"
                            src="./images/banner-images/Q9w8ojCU.jpeg?format=auto&quality=80" alt='banner' />
                        <img className="card-fader card-image"
                            src="./images/banner-images/Q9w8ojCU.jpeg?format=auto&quality=80" alt='banner' />
                        <img className="card-fader card-image"
                            src="./images/banner-images/Q9w8ojCU.jpeg?format=auto&quality=80" alt='banner' />
                        <img className="card-fader card-image"
                            src="./images/banner-images/Q9w8ojCU.jpeg?format=auto&quality=80" alt='banner' />
                        <img className="card-fader card-image"
                            src="./images/banner-images/Q9w8ojCU.jpeg?format=auto&quality=80/" alt='banner' />
                        <img className="card-fader card-image"
                            src="./images/banner-images/Q9w8ojCU.jpeg?format=auto&quality=80" alt='banner' />
                        <img className="card-fader card-image"
                            src="./images/banner-images/Q9w8ojCU.jpeg?format=auto&quality=80" alt='banner' />
                        <img className="card-fader card-image"
                            src="./images/banner-images/Q9w8ojCU.jpeg?format=auto&quality=80" alt='banner' />
                    </div>
                </div>
                <div className="card"></div>
            </div>
        </>
    );
}

export default Cards;
