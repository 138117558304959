import { HashConnect } from 'hashconnect'
import axios from 'axios';
import {
    TransactionId, TokenAssociateTransaction, TransferTransaction, TokenDissociateTransaction, PrivateKey, Client, AccountId
} from '@hashgraph/sdk';
let hashconnect = new HashConnect()

let appMetadata = {
    name: "TRACE WAR",
    description: "This comic book cover is a Founders Token Exclusive for Founders Token Holders.",
    icon: "https://web3.liithos.com/logo-small-white.svg",
    url: "http://localhost:3001"
}

export const initConnection = async () => {
    let initData = await hashconnect.init(appMetadata, "mainnet", false);
    return initData;
}

export async function manualListener(setAccountId, setIsTokenAssociated, setIsTokenOwner, setCanClaim, setIsOpen, setLoading) {
    hashconnect.pairingEvent.once((pairingData) => {
        authenticateUser(pairingData, setAccountId, setIsTokenAssociated, setIsTokenOwner, setCanClaim, setLoading)
        setIsOpen(false)
    })
}

export const disconnect = async () => {
    try {
        let initData = await initConnection();
        let topic = initData.topic;
        await hashconnect.disconnect(topic)
        localStorage.clear();
    } catch (error) {

    }
}

export const associate = async () => {
    try {
        let initData = await initConnection();
        const topic = initData.topic;
        const accountId = initData.savedPairings[0].accountIds[0]
        const token = await getToken();
        let tokenIds = [];
        tokenIds.push(token)
        let transId = TransactionId.generate(accountId)
        const tx = new TokenAssociateTransaction()
            .setAccountId(accountId)
            .setTokenIds(tokenIds)
            .setNodeAccountIds([new AccountId(3)])
            .setTransactionId(transId)
            .freeze()
        let transBytes = await makeBytes(tx);
        const transaction = {
            topic: topic,
            byteArray: transBytes,
            metadata: {
                accountToSign: accountId,
                returnTransaction: false,
                hideNft: false
            }
        }
        let res = await hashconnect.sendTransaction(topic, transaction);
    } catch (error) {

    }
}

export const disassociate = async () => {
    try {
        let initData = await initConnection();
        const accountId = initData.savedPairings[0].accountIds[0]
        const topic = initData.topic;
        const token = await getToken();
        let tokenIds = [];
        tokenIds.push(token)
        let transId = TransactionId.generate(accountId)
        const tx = new TokenDissociateTransaction()
            .setAccountId(accountId)
            .setTokenIds(tokenIds)
            .setNodeAccountIds([new AccountId(3)])
            .setTransactionId(transId)
            .freeze()
        let transBytes = await makeBytes(tx);
        const transaction = {
            topic: topic,
            byteArray: transBytes,
            metadata: {
                accountToSign: accountId,
                returnTransaction: false,
                hideNft: false
            }
        }
        let res = await hashconnect.sendTransaction(topic, transaction);
    } catch (error) {

    }
}


async function makeBytes(trans) {
    let transBytes = trans.toBytes();
    return transBytes;
}


async function getUserBalance() {
    try {
        const data = JSON.parse(localStorage.getItem("hashconnectData"))
        const accountId = data.pairingData[0].accountIds[0];
        const response = await axios.get(process.env.REACT_APP_HEDERA_NODE + '/accounts/' + accountId)
        const balance = response.data.balance.balance;
        return balance;
    } catch (error) {
        // console.log(error)
    }
}

async function getToken() {
    try {
        let data = localStorage.getItem("accessToken");
        if (data) {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: process.env.REACT_APP_API_BASE_URL + '/getToken/get',
                headers: {
                    'Authorization': 'Bearer ' + data
                }
            };
            const token = await axios.request(config)
            return token.data.payload.token;
        }
    } catch (error) {

    }
}


export async function claimToken() {
    try {
        const balance = await getUserBalance();
        if (balance >= 1000000) {
            let data = localStorage.getItem("accessToken");
            if (data) {
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: process.env.REACT_APP_API_BASE_URL + '/claimToken/get',
                    headers: {
                        'Authorization': 'Bearer ' + data
                    }
                };

                const token = await axios.request(config);
                const transBytes = token.data.payload.transBytes;
                const transaction = await signTransaction(transBytes)
                const status = {
                    status: transaction,
                    amount: token.data.payload.amount,
                    serials: token.data.payload.serials
                }
                return status;
            }
        }
    } catch (error) {

    }
}

export const signTransaction = async (transBytes) => {
    try {
        let initData = await initConnection();
        const topic = initData.topic;
        const accountId = initData.savedPairings[0].accountIds[0]
        const provider = hashconnect.getProvider("mainnet", topic, accountId)
        const signer = hashconnect.getSigner(provider)
        const tx = TransferTransaction.fromBytes(transBytes.data)
        const submit = await tx.executeWithSigner(signer)
        if (submit) {
            return true
        } else {
            return false;
        }

    } catch (error) {

    }
}

export const pairHashpack = async (setAccountId, setIsTokenAssociated, setIsTokenOwner, setCanClaim, setLoading) => {
    try {
        let initData = await initConnection();
        hashconnect.foundExtensionEvent.once((walletMetadata) => {
            hashconnect.connectToLocalWallet(initData.pairingString, walletMetadata);
        })

        hashconnect.foundIframeEvent.once((walletMetadata) => {
            hashconnect.connectToLocalWallet(initData.pairingString, walletMetadata);
        })

        hashconnect.pairingEvent.once((pairingData) => {
            authenticateUser(pairingData, setAccountId, setIsTokenAssociated, setIsTokenOwner, setCanClaim, setLoading)
        })
        return initData
    } catch (error) {

    }
}

export const authenticateUser = async (pairingData, setAccountId, setIsTokenAssociated, setIsTokenOwner, setCanClaim, setLoading) => {
    try {
        setLoading(true)
        const res = await fetch(
            process.env.REACT_APP_API_BASE_URL + "/sendAuth/get"
        ).then((response) => response.json());
        setLoading(false)
        const signingData = res.payload
        const serverSigAsArr = Object.values(signingData.serverSignature)
        const serverSigAsBuffer = Buffer.from(serverSigAsArr)
        setLoading(true)
        let auth = await hashconnect
            .authenticate(pairingData.topic, pairingData.accountIds[0], signingData.serverSigningAccount, serverSigAsBuffer, signingData.payload);
            setLoading(false)
        const receiveAuthData = {
            signingAccount: pairingData.accountIds[0],
            auth
        }
        setLoading(true)
        const verification = await fetch(process.env.REACT_APP_API_BASE_URL + '/getAuth/post', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(receiveAuthData),
        }).then((response) => response.json());

        setLoading(false)
        if (verification.message === 'Success') {
            setAccountId(pairingData.accountIds[0])
            setCanClaim(verification.payload.isFounder)
            setIsTokenAssociated(verification.payload.isAssociated)
            setIsTokenOwner(verification.payload.isOwner)
        }

        localStorage.setItem("accessToken", verification.payload.token)
    } catch (error) {
        // console.log(error)
    }
}

export const updateToken = async (serials) => {
    try {
        let data = localStorage.getItem("accessToken");
        if (data) {
            const update = await fetch(process.env.REACT_APP_API_BASE_URL + '/updateToken/post', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data
                },
                body: JSON.stringify({ serials }),
            }).then((response) => response.json());
            return update.payload.payload.status
        }
    } catch (error) {

    }
}