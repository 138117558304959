import { useState, useEffect } from 'react';
import QRCode from "react-qr-code";
import './App.css';
import axios from 'axios';

import './modal.css';
import Modal from "react-modal";
import { pairHashpack, initConnection, disconnect, associate, disassociate, claimToken, updateToken,  manualListener } from './hashconnect';

import Cards from './components/Cards';

function App() {
    const [isOpen, setIsOpen] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pairingString, setPairingString] = useState('');
    const [accountId, setAccountId] = useState("");
    const [isTokenAssociated, setIsTokenAssociated] = useState(false);
    const [isTokenOwner, setIsTokenOwner] = useState(true);
    const [canClaim, setCanClaim] = useState(true);
    const [successFul, setSuccessful] = useState(false );
    const [successMessage, setSuccessMessage] = useState("");
    const [amount, setAmount] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState("Loading ...");

    useEffect(() => {
      getUserData()
    }, []);
     
    async function getUserData() {
        try {            
            setLoadingMessage("Fetching user Data")
            setLoading(true)
            let data = localStorage.getItem("accessToken");
            if (data) {
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: process.env.REACT_APP_API_BASE_URL+'/getUser/get',
                    headers: {
                        'Authorization': 'Bearer ' + data
                    }
                };
    
               await axios.request(config)
                    .then((response) => {
                        const id = response.data.payload.id
                        if (id) {
                            setAccountId(id)
                            setCanClaim(response.data.payload.isFounder)
                            setIsTokenAssociated(response.data.payload.isAssociated)
                            setIsTokenOwner(response.data.payload.isOwner)
                            setIsConnected(true)
                        }
                        setLoading(false)
                        setLoadingMessage("Loading...")
                    })
                    .catch((error) => {
                        // console.log(error);
                    })
            }else{
                setLoading(false)
                setLoadingMessage("Loading...")
            }
        } catch (error) {
            
        }
    }


    async function addToClipoBoard(message: string) {
        navigator.clipboard.writeText(message)
            // manualListener(setAccountId, setIsTokenAssociated, setIsTokenOwner, setCanClaim)
    }

    async function browserExtension() {
        try {            
            setLoadingMessage("Pairing...")
            setLoading(true)
            await pairHashpack(setAccountId, setIsTokenAssociated, setIsTokenOwner, setCanClaim, setLoading);
            closeModal();
            setIsConnected(true)
            setLoading(false)
            setLoadingMessage("Loading...")
        } catch (error) {
            
        }
    }
    
    async function disconnectBrowserExtension() {
        try {         
            setLoadingMessage("Disconnecting...")
            setLoading(true)
            await disconnect();
            setAccountId("")
            setCanClaim(false)
            setIsTokenAssociated(false)
            setIsTokenOwner(false)
            setLoading(false)
            setLoadingMessage("Loading...")
            setIsConnected(false)
        } catch (error) {
            
        }
    }

    async function associateToken() {
        try {            
            setLoading(true)
            await associate();
            const tokens = await claim();
            setLoading(false)
            // showSuccessModal(tokens)
        } catch (error) {
            
        }
      
    }

    async function showSuccessModal(tokens:any) {
        try {            
            setAmount(tokens)
            // setSuccessful(true);
            await getUserData();
        } catch (error) {
            
        }
    }

    async function disassociateToken() {
        try {            
            setLoading(true)
            await disassociate();
            setLoading(false)
        } catch (error) {
            
        }
    }

    async function claim() {
        try {
            setLoadingMessage("Generating Transaction...")
            setLoading(true)
            const operation:any = await claimToken();

            if(operation.status==true){
                const status:any = await updateToken(operation.serials)
                if(status == true){
                   setSuccessMessage(" Token(s) sent successfully!")
                   setSuccessful(true)
                   showSuccessModal(1)
               }else{
                setSuccessMessage(" Something went wrong.")
                setSuccessful(false)
                showSuccessModal(1)
               }
            }
            setLoading(false)
            setLoadingMessage("Loading...")
        } catch (error) {
            
        }
    }

    async function openModal() {
        try {            
            setIsOpen(true);
            const saveData = await initConnection()
            setPairingString(saveData.pairingString)
            manualListener(setAccountId, setIsTokenAssociated, setIsTokenOwner, setCanClaim, setIsOpen, setLoading)
        } catch (error) {
            
        }
    }

    async function closeModal() {
        setIsOpen(false);
    }

    
    async function close() {
        try {      
            setLoading(true)
            await getUserData();
            setLoading(false)
            setSuccessful(false);
        } catch (error) {
            
        }
    }


    return (
        <>
            <header role="banner">
                <nav className="navbar navbar-expand-md navbar-dark bg-dark">
                    <div className="container">
                        <a className="navbar-brand" href="/">
                            <img src='/images/HeaderLogo.svg' width='150px' alt='logo' />
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample05"
                            aria-controls="navbarsExample05" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarsExample05">
                             <ul className="navbar-nav ml-auto pl-lg-5 pl-0">
                                <li className="nav-item">
                                    <a className="nav-link active" href="https://liithos.com/about-us">About</a>
                                </li> 
                                <li className="nav-item">
                                    <a className="nav-link" href="https://liithos.com/blog/the-trace-war-kickstarter-blog"> Trace War Kickstarter</a>
                                </li> 
                              
                                <li className="nav-item">
                                    <a className="nav-link" href="https://liithos.com">Liithos</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://liithos.com/blog">News</a>
                                </li>
                            </ul> 
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item ">
                                    {accountId ? (

                                        <button className="wallet" id="accountid" onClick={disconnectBrowserExtension}> {accountId} </button>
                                    ) : (
                                        <button className="wallet" id="accountid" onClick={openModal}>Connect Your Wallet</button>
                                    )

                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
            <div className="container home">
                <div className="row">
                    <div className="col-sm-5">
                        <div className="section-heading">

                            <h1>TRACE WAR DROP</h1>
                            <h2>This comic book cover is a Founders Token Exclusive for Founders Token Holders.
                                Continue to follow the exciting world of Trace War, following the story of Ashe.</h2>
                            <div id="countdown">
                                <ul>
                                    <li className="countdown"><span id="days"></span>days</li>
                                    <li className="countdown"><span id="hours"></span>Hours</li>
                                    <li className="countdown"><span id="minutes"></span>Minutes</li>
                                    <li className="countdown"><span id="seconds"></span>Seconds</li>
                                </ul>
                            </div>
                            <h6 id='content'> Airdrop Countdown in Progress</h6>
                            <h6 id='headline'></h6>

                            {
                                 (isTokenAssociated && isTokenOwner && canClaim)
                                 ? 
                                 <p id='claimBtn'>Thank You for Participating in Our Airdrop!</p>
                                 :
                                 (isTokenAssociated && !isTokenOwner && canClaim)
                                 ?
                                 <button className="wallet action" onClick={claim} id='claimBtn'> Claim Token </button>
                                 :
                                 (!isTokenAssociated && !isTokenOwner && canClaim)
                                 ?
                                 <button className="wallet action" onClick={associateToken} id='claimBtn'>Associate Token </button>
                                 :
                                 (isTokenAssociated===false && isTokenOwner===false && canClaim===false && isConnected===true)
                                 ?
                                 <p id='claimBtn'> Sorry, you are ineligible for this drop. </p>
                                 :
                                 <p id='claimBtn'> </p>
                            }

                        </div>
                    </div>
                    <div className="col-sm-3"></div>
                    <div className="col-sm-3">
                        <Cards/>
                    </div>
                </div>

            </div>
            <Modal
                ariaHideApp={false}
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                <div className='close'>
                    <button className="btn modal-btn float-right" onClick={closeModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3 16.74L7.76 12L3 7.26L7.26 3L12 7.76L16.74 3L21 7.26L16.24 12L21 16.74L16.74 21L12 16.24L7.26 21L3 16.74m9-3.33l4.74 4.75l1.42-1.42L13.41 12l4.75-4.74l-1.42-1.42L12 10.59L7.26 5.84L5.84 7.26L10.59 12l-4.75 4.74l1.42 1.42L12 13.41Z" /></svg>
                    </button>
                </div>

                <div>
                    <h3 className='modal-text ml-4 mb-3'>
                        scan the QR Code with your HashPack mobile app
                    </h3>
                    <QRCode value={pairingString} className='rounded mx-auto d-block mt-2 mb-5' />
                </div>
                <button className="btn--secondary mb-2" onClick={() => { addToClipoBoard(pairingString) }}>Copy Pairing String</button>
                <button className="btn--primary mb-2" onClick={browserExtension}>
                    <span>Browser Extension</span>
                </button>

            </Modal>

            <Modal
                ariaHideApp={false}
                isOpen={successFul}
                contentLabel="My dialog"
                className="loading-modal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}>
                <div className='d-flex justify-content-center'>
                    <h3 className='modal-loading-text' >
                     <span>{successMessage}</span> 
                   </h3>     
                </div>
                <div className='d-flex justify-content-center'> 
                   <div><button className='btn btn-success' onClick={close}>Close</button></div>    
                </div>
            </Modal>

            <Modal
                ariaHideApp={false}
                isOpen={loading}
                contentLabel="My dialog"
                className="loading-modal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}>
                <div className='d-flex justify-content-center'>
                    <h3 className='modal-loading-text' >
                     <span>{ loadingMessage }</span> <div className="lds-dual-ring"></div>
                   </h3>      
                </div>
            </Modal>
        </>
    );
}

export default App;
